.sidebar-toggle {
  border-radius: 50%;
  background-color: rgba(0,0,0,0.1);
  border: none;
  color: rgba(255,255,255,0.8);
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
}

.logo {
  display: block;
  margin: 30px auto 20px;
  width: 75%;
  max-height: 70px;
}

@media (min-width: 768px) {
  .sidebar {
    max-width: 50px !important;
    overflow-x: hidden;
  }

  .sidebar.isOpen {
    max-width: 250px !important;
  }
}

:global(.navbar-dark.navbar-vibrant) {
  transform: translate3d(0, 0, 0); /* force hardware acceleration if available to make transition smoother */
  transition: all 0.3s;
  background-image: linear-gradient(to bottom right, #121220, rgba(18, 18, 32, 0.9));
}

