/* Wrapper to contain the sidebar and content, letting the sidebar be able to take full height */
.wrapper {
  min-height: 100%;
  height: 100vh;
}

.content {
  flex: 1;
  transform: translate3d(0, 0, 0); /* force hardware acceleration if available to make transition smoother */
  transition: all 0.3s;
  padding: 0 36px;
}

.content.isOpen {
  position: relative;
}

.content {
  overflow: auto;
  height: 100%;
  margin-left: 0;
}

@media (min-width: 768px) {
  .content.isOpen {
    margin-left: 250px;
    position: relative;
  }

  .content {
    margin-left: 50px;
  }
}

